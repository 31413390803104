.App {
  width: 100vw;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}

body {
  margin: 0;
  padding: 0;
}

.post {
  width: 400px;
  height: 300px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  border: 1px solid lightgray;
  font-family: Arial, Helvetica, sans-serif;

  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.post:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  cursor: pointer;
}
.post .title {
  flex: 20%;
  border-bottom: 1px solid lightgray;
  background-color: dodgerblue;
  display: grid;
  place-content: center;
  color: white;
}

.post .body {
  flex: 60%;
  display: grid;
  place-content: center;
}

.post .footer {
  flex: 20%;
  border-top: 1px solid lightgray;
  display: flex;
  align-items: center;
  padding-left: 15px;
  background-color: dodgerblue;
  color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.footer .username {
  flex: 50%;
}
.footer .buttons {
  flex: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.likeBttn {
  color: rgba(250, 250, 250, 0.8);
}
.unlikeBttn {
  color: white;
}

.buttons label {
  margin-right: 20px;
  margin-left: 10px;
}
.createPostPage {
  font-family: Arial, Helvetica, sans-serif;
  width: 100vw;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 500px;
  height: auto;
  padding: 20px;
  border: 5px solid dodgerblue;
  border-radius: 5px;
}

.formContainer .inputCreatePost {
  height: 40px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 2px solid grey;
  border-radius: 5px;
  padding-left: 10px;
  font-size: 20px;
}

.formContainer button {
  margin-top: 15px;
  height: 40px;
  border: none;
  background-color: lightskyblue;
  border-radius: 5px;
  color: white;
}

.formContainer button:hover {
  cursor: pointer;
  background-color: dodgerblue;
}

span {
  color: red;
}

.navbar {
  width: 100%;
  height: 70px;
  background-color: dodgerblue;
  display: flex;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
}
.navbar .links {
  flex: 50%;
  height: 100%;
  display: flex;
  align-items: center;
}

.navbar .loggedInContainer {
  flex: 50%;
  height: 100%;
  margin-right: 2rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.navbar a {
  margin-left: 20px;
  text-decoration: none;
  color: white;
}

.navbar button {
  width: 100px;
  height: 40px;
  cursor: pointer;
  margin-right: 10px;
  background-color: white;
}

.navbar h1 {
  text-align: right;
  margin-right: 10px;
  color: white;
  font-weight: lighter;
}


.postPage {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.leftSide {
  flex: 50%;
  height: calc(100vh - 70px);
  display: grid;
  place-items: center;
}

.rightSide {
  flex: 50%;
  height: calc(100vh - 70px);
  display: grid;
  place-items: center;
}

.rightSide .addCommentContainer {
  flex: 20%;
  width: 100%;
  display: grid;
  place-items: center;
  margin-top: 25px;
}

.addCommentContainer input,
button {
  width: 200px;
  height: 50px;
  border-radius: 8px;
  border: none;
}

.addCommentContainer button {
  background-color: dodgerblue;
  color: white;
}

.addCommentContainer input {
  border: 2px solid dodgerblue;
}

.rightSide .listOfComments {
  flex: 80%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.listOfComments .comment {
  width: 70%;
  height: auto;
  padding: 30px;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-top: 20px;
}

#individual {
  height: 500px;
  width: 600px;
}

#individual .title,
#individual .footer {
  font-size: 30px;
}

#individual .body {
  font-size: 25px;
}

.loginContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding-top: 100px;
}

.loginContainer input,
button {
  width: 200px;
  height: 50px;
  border-radius: 8px;
  border: none;
  margin: 10px;
}

.loginContainer button {
  background-color: dodgerblue;
  color: white;
}

.loginContainer input {
  border: 2px solid dodgerblue;
}